import {
    createBrowserRouter,
    createHashRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import { lazy, Suspense } from "react";

// Application Base pages
import App from "../App";
import NotFound from "../pages/NotFound";
import Loading from "../pages/Loading";

const Test = lazy(() => import("../pages/Test/index"));
const Login = lazy(() => import("../pages/Login/index"));
const NfcScanner = lazy(() => import("../pages/NfcScanner/index"));
const AssetsList = lazy(() => import("../pages/AssetsList/index"));
const AppIndex = lazy(() => import("../pages/AppIndex"));
const BarcodeScanner = lazy(() => import("../pages/BarcodeScanner/index"));
const Completed = lazy(() => import("../pages/Completed/index"));

const TakePhoto = lazy(() => import("../pages/AssetDetails/TakePhoto"));
const Status = lazy(() => import("../pages/AssetDetails/Status"));
const Remark = lazy(() => import("../pages/AssetDetails/Remark"));
const Preview = lazy(() => import("../pages/AssetPreview/Preview"));

// Setup react-router
const router = (() => {
    return createHashRouter(
        createRoutesFromElements(
            <Route>
                <Route element={<App />} >
                    <Route path="/NfcScanner" element={<Suspense fallback={<Loading />}><NfcScanner /></Suspense>} />
                    <Route path="/AssetsList" element={<Suspense fallback={<Loading />}><AssetsList /></Suspense>} />
                    <Route path="/BarcodeScanner" element={<Suspense fallback={<Loading />}><BarcodeScanner /></Suspense>} />
                    <Route path="/AssetDetails">
                        <Route path="TakePhoto" element={<Suspense fallback={<Loading />}><TakePhoto /></Suspense>} />
                        <Route path="Status" element={<Suspense fallback={<Loading />}><Status /></Suspense>} />
                        <Route path="Remark" element={<Suspense fallback={<Loading />}><Remark /></Suspense>} />
                        <Route path="Preview" element={<Suspense fallback={<Loading />}><Preview /></Suspense>} />
                    </Route>
                    <Route path="/Completed" element={<Suspense fallback={<Loading />}><Completed /></Suspense>} />
                </Route>
                <Route path="/Login" element={<Suspense fallback={<Loading />}><Login /></Suspense>} />
                <Route path="/Test" element={<Suspense fallback={<Loading />}><Test /></Suspense>} />
                <Route path="/" element={<Suspense fallback={<Loading />}><AppIndex /></Suspense>} />
                <Route path="*" element={<NotFound />} />
            </Route>
        )
    );
})();

export default router;
