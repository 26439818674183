import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

// Import React Router
import { RouterProvider } from "react-router-dom";
import router from "./router/router";

// Import some project-wide base stylesheet
import "primereact/resources/themes/lara-light-green/theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-slideshow-image/dist/styles.css";
import "react-photo-view/dist/react-photo-view.css";
import "react-html5-camera-photo/build/css/index.css";
import "./index.scss";

// Setup i18n
import "./utils/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
