import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const message = t("msg_system_invalid_url", { url: window.location.href });

    useEffect(() => {
        setTimeout(() => {
            navigate("");
        }, 5000);
    });

    return (
        <div className="NotFound">
            <h1>
                <Trans>title_system_invalid_url</Trans>
            </h1>
            <p>{message}</p>
        </div>
    );
}
