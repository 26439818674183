import { Outlet } from "react-router-dom";

import Banner from "./components/banner/banner";

export function setBanner(path: string) {
  const noBackPage = ["NfcScanner", "AssetsList", "Completed"];
  const useBack = !noBackPage.includes(path.split("/").pop() as string);

  document
    .getElementById("header-back")
    ?.setAttribute("style", `display: ${useBack ? "block" : "none"}`);
}

export default function App() {
  return (
    <div>
      <header className="App-header">
        <Banner />
      </header>
      <Outlet />
    </div>
  );
}
