import { useNavigate } from "react-router-dom";

import "./banner.scss";

import iconBack from "../../assets/btn_back.png";
import iconSignOut from "../../assets/icon_signout.png";
import { logout } from "../../utils/requester";


export default function Banner() {
    const navigate = useNavigate();

    const back = () => {
        window.history.back();
    };
    const signOut = () => {
        logout();
        navigate("/Login");
    };

    return (
        <div className="single-row banner">
            <img
                id="header-back"
                src={iconBack}
                onClick={back}
                className="link"
                tabIndex={0}
            />
            <div className="empty"></div>
            <img
                id="header-sign-out"
                src={iconSignOut}
                onClick={signOut}
                className="link"
                tabIndex={0}
            />
        </div>
    );
}
