import { StocktakeAssetItem } from "../models";
import logger, { config } from "./logger";
import temp from "../temp.json";

let token: string | null = null;
const ApiPrefix = "/api/Mobile/";

export interface ApiResponse {
    ok: boolean;
    status: number;
    title: string;
}

export interface DataStructBase {
    isDisabled: boolean;
    createTime: string;
    createBy?: string;
    updateTime?: string;
    updateBy?: string;
    remark?: string;
}

export interface SearchParamsBaseModel {
    page?: number;
    pageSize?: number;
    sortColumn?: string;
    sortDirection?: "ASC" | "DESC";
}

export interface ApiResponseT<T> extends ApiResponse {
    count: number;
    result: T;
}

const getDefaultRequestInit = (init?: RequestInit) => {
    if (init == null) {
        init = {};
    }
    if (init.headers == null) {
        init.headers = {};
    }
    (init.headers as Record<string, string>)["Accept"] = "application/json";
    if (token !== null) {
        (init.headers as Record<string, string>)[
            "Authorization"
        ] = `Bearer ${token}`;
    }
    return init as RequestInit;
};

const getLocationInfo = async (geolocation: Geolocation) => {
    let { lat, lng }: { lat: number; lng: number } = { lat: -1, lng: -1 };
    geolocation.getCurrentPosition(
        async (position) => {
            logger.log(JSON.stringify(position.coords));
            lat = position.coords.latitude;
            lng = position.coords.longitude;
        },
        (error) => {
            logger.error(error.code + " | " + error.message);
        }
    );
    return {
        lat: lat.toString(),
        lng: lng.toString(),
    };
};

export const RawRequest = async (
    url: string,
    init?: RequestInit
): Promise<Response> => {
    const lInit = getDefaultRequestInit(init);
    return (await fetch(config.base_url + ApiPrefix + url, lInit)) as Response;
};

export const Request = async (
    url: string,
    init?: RequestInit
): Promise<[ApiResponse | null, Response]> => {
    logger.log(
        [
            "URL=" + config.base_url + ApiPrefix + url,
            "Method=" + init?.method,
            "Headers=" + JSON.stringify(init?.headers),
            "Body=" +
            ((JSON.stringify(init?.body) ?? "").indexOf(':') > 0
                ? JSON.stringify(init?.body)
                : `{${Array.from(
                    ((init?.body as FormData | URLSearchParams) ?? []).entries()
                )
                    .map(([key, value]) => `${key}: "${value}"`)
                    .join(",")}}`),
        ].join("\n")
    );
    let fetchResult: Response = new Response();
    let json: ApiResponse | null = null;
    try {
        fetchResult = await fetch(config.base_url + ApiPrefix + url, init);
        if (fetchResult.status === 401) {
            window.location.href = "/";
        }
        json = await fetchResult.json();
    } catch (e) {
        // may convert to json error
    }
    logger.log(
        [
            "URL=" + config.base_url + ApiPrefix + url,
            "Response=" + JSON.stringify(json),
        ].join("\n")
    );
    return [json, fetchResult];
};

export const RequestPath = (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    return Request(url, lInit);
};

export const RequestFormData = (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    (lInit.headers as Record<string, string>)["Content-Type"] =
        "multipart/form-data";
    return Request(url, lInit);
};

export const RequestUrlEncoded = (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    (lInit.headers as Record<string, string>)["Content-Type"] =
        "application/x-www-form-urlencoded";
    return Request(url, lInit);
};

export const RequestJson = async (url: string, init?: RequestInit) => {
    const lInit = getDefaultRequestInit(init);
    (lInit.headers as Record<string, string>)["Content-Type"] =
        "application/json";
    return Request(url, lInit);
};

export const login = async (
    geo: Geolocation,
    id: string,
    password: string,
    clientTime: string
) => {
    const locationInfo = await getLocationInfo(geo);
    const result = await RequestUrlEncoded("Session/Login", {
        method: "POST",
        body: new URLSearchParams({
            ticketId: id,
            password: password,
            clientTime: clientTime,
            lat: locationInfo.lat,
            lng: locationInfo.lng,
        }),
    });
    if (result[0] && result[1] && (result[0] as any).accessToken) {
        result[0].ok = result[1].ok;
        result[0].status = result[1].status;
        token = (result[0] as any).accessToken;
    }
    return result[0] ?? result[1] ?? { ok: false };
};

export const logout = async () => {
    const result = await RequestUrlEncoded("Session/Logout", {
        method: "POST",
    });
    if (result[0] && result[0].ok) {
        token = null;
    }
    return result[0] ?? result[1] ?? { ok: false };
};

export const verifyLocation = async (
    geo: Geolocation,
    platform: string = temp.platform,
    nfcTagId: string = temp.nfcFactoryCode
) => {
    const locationInfo = await getLocationInfo(geo);
    const result = await RequestUrlEncoded("Session/LocationVerification", {
        method: "POST",
        body: new URLSearchParams({
            lat: locationInfo.lat,
            lng: locationInfo.lng,
            nfcTagId: nfcTagId,
            deviceType: platform,
            location: "Removes",
        }),
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const getProjectId = async () => {
    const result = await RequestPath("Session/GetCurrentUsername", {
        method: "GET",
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const getAssetsList = async (
    geo: Geolocation,
    id: string | null = null
) => {
    const result = await RequestPath("Assets" + (id ? `/${id}` : ""), {
        method: "GET",
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const getStocktakeTicketAsset = async (geo: Geolocation, id: number) => {
    const result = await RequestPath("StocktakeTicketAssets" + `/${id}`, {
        method: "GET",
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const submitAssets = async (data: any) => {
    const result = await RequestJson("Stocktake/SubmitAssets", {
        method: "POST",
        body: JSON.stringify([data]),
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const submitOperatorName = async (operatorName: string) => {
    const result = await RequestUrlEncoded("Stocktake/Submit", {
        method: "POST",
        body: new URLSearchParams({
            operatorName: operatorName,
        }),
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const getPictures = async (id: number) => {
    const result = await RawRequest("Pictures/" + id, {
        method: "GET",
    });
    return result;
};

export const uploadPictures = async (
    StocktakeTicketAssetId: number,
    pictureblob: Blob
) => {
    const data = new FormData();
    data.append("StocktakeTicketAssetId", String(StocktakeTicketAssetId));
    data.append("files", pictureblob, new Date().getTime() + ".jpg");
    const result = await RequestPath("Pictures/AddByStocktakeAsset", {
        method: "POST",
        body: data,
    });
    return result[0] ?? result[1] ?? { ok: false };
};

export const removePictures = async (id: number) => {
    const result = await RequestPath("Pictures/DeleteByStocktakeAsset/" + id, {
        method: "DELETE",
    });
    return result[0] ?? result[1] ?? { ok: false };
}