import { Trans, useTranslation } from "react-i18next";

import "./androidToast.scss";

export default function Loading() {
    const { t, i18n } = useTranslation();

    const message = t("msg_system_loading");

    return (
        <div className="Loading">
            <h1
                style={{
                    display: "none",
                }}
            >
                <Trans>msg_system_loading</Trans>
            </h1>
            <p className="android-toast toast android-toast-show">{message}</p>
        </div>
    );
}
