export const config = (await (await fetch("config.json")).json()) as {
    base_url: string;
    debug: boolean;
};

interface Logger {
    log: (message?: any, ...optionalParams: any[]) => void;
    error: (message?: any, ...optionalParams: any[]) => void;
    warn: (message?: any, ...optionalParams: any[]) => void;
}

const logger: Logger = {
    log: function (message?: any, ...optionalParams: any[]): void {
        if (config.debug) {
            if (optionalParams.length > 0) {
                console.log("LOG: \n" + message, optionalParams);
            } else {
                console.log("LOG: \n" + message);
            }
        }
    },
    error: function (message?: any, ...optionalParams: any[]): void {
        if (optionalParams.length > 0) {
            console.error("ERROR: \n" + message, optionalParams);
        } else {
            console.error("ERROR: \n" + message);
        }
    },
    warn: function (message?: any, ...optionalParams: any[]): void {
        if (optionalParams.length > 0) {
            console.warn("WARN: \n" + message, optionalParams);
        } else {
            console.warn("WARN: \n" + message);
        }
    }
}

export default logger;